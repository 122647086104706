
  import {
    defineComponent, ref
  } from 'vue';
  import i18n from '@/i18n'
  import AvertissementEcran from '@/components/AvertissementEcran.vue';
  import Header from '@/components/Header.vue';
  import MonParcour from '@/components/MonParcour.vue';
  import MesCompetences from '@/components/MesCompetences.vue';
  import MesExperiencePro from '@/components/MesExperiencePro.vue';
  import MesProjetAcademiques from '@/components/MesProjetAcademiques.vue';
  import MesCentresDinterets from '@/components/MesCentresDinterets.vue';
  import Contact from '@/components/Contact.vue';
  import MiseAJour from '@/components/MiseAJour.vue';

  export default defineComponent({
    name: 'App',
    components: {
      AvertissementEcran,
      Header,
      MonParcour,
      MesCompetences,
      MesExperiencePro,
      MesProjetAcademiques,
      MesCentresDinterets,
      Contact,
      MiseAJour
    },
    setup() {
      const setI18nLangage = (langage: string) => {
        i18n.global.locale = langage
        getI18nLangage.value = i18n.global.locale
      }

      const getI18nLangage = ref(i18n.global.locale)
      
      const langageOptions = [{
          value: 'fr',
          name: 'Français'
        },
        {
          value: 'en',
          name: 'English'
        }
      ]

      const i18nManager = {
        langageOptions,
        setI18nLangage,
        getI18nLangage
      }

      return {
        i18nManager
      }
    }
  });
