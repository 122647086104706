
  import {
    defineComponent,
    ref
  } from 'vue'
  import SelectLangage from '@/utils/SelectLangage.vue'

  export default defineComponent({
    name: 'AvertissementEcran',
    components: {
      SelectLangage
    },
    props: {
      i18nManager: Object
    },
    setup() {

      const avertissementDisplay = ref(false)

      function switchAvertissementDisplay() {
        avertissementDisplay.value = !avertissementDisplay.value
      }

      if (window.innerWidth < 1280 || window.innerHeight < 720) {
        avertissementDisplay.value = true
      }

      return {
        avertissementDisplay,
        switchAvertissementDisplay
      }
    }
  })
