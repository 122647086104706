{
  "header": {
    "sommaire": {
      "titre": "Summary",
      "1": "My journey",
      "2": "My skills",
      "3": "My professional experiences",
      "4": "My academic projects",
      "5": "My interests",
      "6": "Contact"
    }
  },
  "monParcour": {
    "frise": {
      "ecoleIngenieur": "Digital engineering school at Efrei Paris.",
      "licencePro": "Professional License of development at the IUT of Troyes.",
      "dutMMI": "DUT Multimedia and Internet at the IUT of Troyes.",
      "bacSSVT": "Baccalaureate S SVT in Poligny, with the option chinese."
    }
  },
  "mesCompetences": {
    "titreSection": {
      "global": "Global",
      "technique": "Technics"
    },
    "global": {
      "langue": {
        "titre": "Languages",
        "1": "French",
        "2": "English ~800 TOEIC"
      },
      "habilitees": {
        "titre": "Skills",
        "1": "Teamwork",
        "2": "Fast learning",
        "3": "Team management",
        "4": "Adaptability"
      },
      "formations": {
        "titre": "Trainings",
        "1": "Software engineer",
        "2": "Full-stack engineer",
        "3": "DevOps engineer",
        "4": "Chef de projet"
      }
    },
    "technique": {
      "apprisLycee": "Learned in high school.",
      "apprisDUT": "Learned in DUT.",
      "apprisLicence": "Learned under license.",
      "apprisInge": "Learned in engineering school.",
      "certifierOCR": "Open-Class-Rooms certified.",
      "certifierOCRProgression": "Open-Class-Rooms certification in progress.",
      "voireCertificat": "View certificate",
      "voireTousCertificat": "Show all certificates"
    }
  },
  "mesExperiencesPro": {
    "1": {
      "dateDebut": "October 2018",
      "dateFin": "August 2019",
      "entreprise": "Neofox",
      "role": "Self-employed web developer.",
      "context": "Side job while studying."
    },
    "2": {
      "dateDebut": "March 2019",
      "dateFin": "August 2020",
      "entreprise": "Euro Media Group SA",
      "role": "Software solution research and development.",
      "context": "Internship / Apprenticeship."
    },
    "3": {
      "dateDebut": "November 2021",
      "dateFin": "March 2022",
      "entreprise": "Euro Media Group SA",
      "role": "Software solution research and development.",
      "context": "Internship."
    },
    "4": {
      "dateDebut": "September 2021",
      "dateFin": "March 2023",
      "entreprise": "SepEfrei",
      "role": "In charge of Information Systems -> Director of Information Systems.",
      "context": "Junior-Enterprise in the fields of multimedia."
    },
    "5": {
      "dateDebut": "April 2023",
      "dateFin": "September 2023",
      "entreprise": "Société Générale",
      "role": "Developer web full-stacks.",
      "context": "Internship."
    }
  },
  "mesProjetAcademiques": {
    "1": {
      "dateDebut": "October 2017",
      "dateFin": "March 2019",
      "nom": "WebTV Troyes",
      "poste": "Synth Operator & Synth Developer.",
      "description": "Development of synths and various applications for web viewer interaction."
    },
    "2": {
      "dateDebut": "October 2018",
      "dateFin": "November 2018",
      "nom": "AccrosTime",
      "poste": "Web developer.",
      "description": "Conception and development of riddles game."
    },
    "3": {
      "dateDebut": "September 2019",
      "dateFin": "June 2020",
      "nom": "TroisPointZéro",
      "poste": "Web developer.",
      "description": "Creation of an administrable website for a national college event through a university company."
    },
    "4": {
      "dateDebut": "June 2021",
      "dateFin": "July 2021",
      "nom": "Mastercamp",
      "poste": "Project manager and main developer.",
      "description": "React-native project on the digitization of medical prescriptions."
    },
    "5": {
      "dateDebut": "March 2021",
      "dateFin": "March 2022",
      "nom": "Efrei FAP Horizon",
      "poste": "Web and software developer.",
      "description": "College association developing applications to improve students' life."
    },
    "6": {
      "dateDebut": "April 2022",
      "dateFin": "July 2022",
      "nom": "Master-project",
      "poste": "Project manager and main developer.",
      "description": "Web project for the prediction of medical treatments using machine leaning."
    },
    "7": {
      "dateDebut": "September 2021",
      "dateFin": "March 2023",
      "nom": "SepEfrei",
      "poste": "In charge of Information Systems -> Director of Information Systems.",
      "description": "Junior-Enterprise in the fields of multimedia."
    }
  },
  "mesCentresDinterets": {
    "photographie": "Photography (Astro-photography, since 2018)",
    "nouvelleTechnologie": {
      "principal": "New technology",
      "stream": "Streaming technology",
      "web": "Web technology",
      "informatique": "Hardware component",
      "connecter": "Connected object"
    },
    "jeuxVideo": "Multiple video games",
    "tir": "Archery (club and tournament, since 2013)",
    "piano": "Piano (lesson and self-taught, since 2009)"
  },
  "listeCertificts": {
    "titre": "My certifications",
    "afficher": "Display",
    "telecharger": "Download",
    "certificats": {
      "js": "Learn to code with JavaScript",
      "htmlcss": "Learn how to create your website with HTML5 and CSS3",
      "interactiveJS": "Create interactive web pages with JavaScript",
      "illustrator": "Create visuals with Illustrator",
      "vueJS": "Create a web application with Vue.js",
      "react": "Getting started with React",
      "angular": "Develop web applications with Angular",
      "reactNative": "Develop a React Native mobile app",
      "git": "Manage your code with Git and GitHub",
      "machineLearning": "Machine learning is it the future of man ?",
      "apiRest": "Use REST APIs in your web projects"
    }
  },
  "avertissementEcran": {
    "avertissement": "Warning",
    "description": {
      "1": "Warning.",
      "2": "This site was not designed for mobile or small devices!",
      "3": "It is therefore possible that not everything is displayed correctly, if you continue on this device.",
      "4": "Are you sure you want to continue ?"
    },
    "continuer": "Continue"
  },
  "miseAJour": {
    "content": "Last update",
    "date": "09 July 2023"
  }
}