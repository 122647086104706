
  import {
    defineComponent
  } from 'vue'

  export default defineComponent({
    name: 'MonParcour',
    components: {},
    setup() {/*   */}
  })
