
  import {
    defineComponent
  } from 'vue'

  export default defineComponent({
    name: 'MesExperiencePro',
    components: {},
    setup() {
      /*   */
    }
  })
