import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AvertissementEcran = _resolveComponent("AvertissementEcran")!
  const _component_Header = _resolveComponent("Header")!
  const _component_MonParcour = _resolveComponent("MonParcour")!
  const _component_MesCompetences = _resolveComponent("MesCompetences")!
  const _component_MesExperiencePro = _resolveComponent("MesExperiencePro")!
  const _component_MesProjetAcademiques = _resolveComponent("MesProjetAcademiques")!
  const _component_MesCentresDinterets = _resolveComponent("MesCentresDinterets")!
  const _component_Contact = _resolveComponent("Contact")!
  const _component_MiseAJour = _resolveComponent("MiseAJour")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_AvertissementEcran, { i18nManager: _ctx.i18nManager }, null, 8, ["i18nManager"]),
    _createVNode(_component_Header, { i18nManager: _ctx.i18nManager }, null, 8, ["i18nManager"]),
    _createVNode(_component_MonParcour),
    _createVNode(_component_MesCompetences),
    _createVNode(_component_MesExperiencePro),
    _createVNode(_component_MesProjetAcademiques),
    _createVNode(_component_MesCentresDinterets),
    _createVNode(_component_Contact),
    _createVNode(_component_MiseAJour)
  ], 64))
}