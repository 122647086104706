
  import {
    defineComponent
  } from 'vue'
  import SelectLangage from '@/utils/SelectLangage.vue'

  export default defineComponent({
    name: 'Header',
    components: {
      SelectLangage
    },
    props: {
      i18nManager: Object
    },
    setup() {/*   */}
  })
