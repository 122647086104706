
  import {
    defineComponent
  } from 'vue'
  import ListeCertificats from '@/components/ListeCertificats.vue'


  export default defineComponent({
    name: 'MesCompetences',
    components: {
      ListeCertificats
    },
  })
