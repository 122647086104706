
  import {
    defineComponent,
  } from 'vue'

  export default defineComponent({
    name: 'SelectLangage',
    setup() {

      function langageDisplay(langage: string) {
        if (langage === 'en') return 'English'
        return 'Français'
      }

      return {
        langageDisplay
      }
    }
  })
