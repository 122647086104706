import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4e53832e"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "miseAJour",
  class: "flex w-screen justify-center items-center"
}
const _hoisted_2 = { class: "text-gray-700 my-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t('miseAJour.content')) + ": " + _toDisplayString(_ctx.$t('miseAJour.date')) + ".", 1)
  ]))
}