import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { id: "certificatView" }
const _hoisted_2 = {
  key: 0,
  class: "flex flex-col fixed top-0 left-0 w-screen h-screen bg-gray-900 bg-opacity-80 justify-center items-center z-60"
}
const _hoisted_3 = { class: "flex flex-col relative w-3/4 bg-gray-850 rounded border-2 border-gray-800 overflow-y-scroll items-center p-5 z-70" }
const _hoisted_4 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.file.display)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.switchFileModalDisplay(_ctx.file.name))),
            class: "flex absolute top-0 left-0 w-screen h-screen"
          }),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("span", {
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.switchFileModalDisplay(_ctx.file.name))),
              class: "material-icons md-48 absolute top-5 right-5 cursor-pointer text-gray-800"
            }, "close"),
            _createElementVNode("img", {
              src: _ctx.getUrlFile(_ctx.file.name, '.jpg')
            }, null, 8, _hoisted_4)
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}