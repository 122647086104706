{
  "header": {
    "sommaire": {
      "titre": "Sommaire",
      "1": "Mon parcours",
      "2": "Mes compétences",
      "3": "Mes expériences professionnelles",
      "4": "Mes projets académiques",
      "5": "Mes centres d'intérêts",
      "6": "Contact"
    }
  },
  "monParcour": {
    "frise": {
      "ecoleIngenieur": "École d'ingénieur du numérique à Efrei Paris.",
      "licencePro": "Licence Professionnelle de développement à l'IUT de Troyes.",
      "dutMMI": "DUT Métier du Multimédia et de l'Internet à l'IUT de Troyes.",
      "bacSSVT": "Baccalauréat S SVT à Poligny, option chinois."
    }
  },
  "mesCompetences": {
    "titreSection": {
      "global": "Global",
      "technique": "Techniques"
    },
    "global": {
      "langue": {
        "titre": "Langue",
        "1": "Français",
        "2": "English ~800 TOEIC"
      },
      "habilitees": {
        "titre": "Habilitées",
        "1": "Travail d'équipe",
        "2": "Apprentissage rapide",
        "3": "Gestion d'équipe",
        "4": "Capacité à s'adapter"
      },
      "formations": {
        "titre": "Formations",
        "1": "Ingenieur logiciel",
        "2": "Full-stack ingenieur",
        "3": "DevOps ingenieur",
        "4": "Chef de projet"
      }
    },
    "technique": {
      "apprisLycee": "Appris au lycée.",
      "apprisDUT": "Appris en DUT.",
      "apprisLicence": "Appris en licence.",
      "apprisInge": "Appris en école d'ingenieur.",
      "certifierOCR": "Certifié Open-Class-Rooms.",
      "certifierOCRProgression": "Certification Open-Class-Rooms en cours.",
      "voireCertificat": "Voir le certificat",
      "voireTousCertificat": "Afficher tous les certificats"
    }
  },
  "mesExperiencesPro": {
    "1": {
      "dateDebut": "Octobre 2018",
      "dateFin": "Août 2019",
      "entreprise": "Neofox",
      "role": "Développeur Web en auto-entrepreneur.",
      "context": "Travail en parallèle d'étude."
    },
    "2": {
      "dateDebut": "Mars 2019",
      "dateFin": "Août 2020",
      "entreprise": "Euro Media Group SA",
      "role": "Recherche et développement en solution logiciel.",
      "context": "Stage / Étudiant en alternance."
    },
    "3": {
      "dateDebut": "Novembre 2021",
      "dateFin": "Mars 2022",
      "entreprise": "Euro Media Group SA",
      "role": "Recherche et développement en solution logiciel.",
      "context": "Stage."
    },
    "4": {
      "dateDebut": "Septembre 2021",
      "dateFin": "Mars 2023",
      "entreprise": "SepEfrei",
      "role": "Responsable des Systèmes d'Informations -> Directeur des Systèmes d'Informations.",
      "context": "Junior-Entreprise dans les domaines du multimédia."
    },
    "5": {
      "dateDebut": "Avril 2023",
      "dateFin": "Septembre 2023",
      "entreprise": "Société Générale",
      "role": "Développeur web full-stacks.",
      "context": "Stage."
    }
  },
  "mesProjetAcademiques": {
    "1": {
      "dateDebut": "Octobre 2017",
      "dateFin": "Mars 2019",
      "nom": "WebTV Troyes",
      "poste": "Opérateur synthé & Développeur synthé.",
      "description": "Développement de synthé et diverses applications pour l'interaction spectateurs en web."
    },
    "2": {
      "dateDebut": "Octobre 2018",
      "dateFin": "Novembre 2018",
      "nom": "AccrosTime",
      "poste": "Développeur Web.",
      "description": "Élaboration et développement d'un jeu d'énigmes en web."
    },
    "3": {
      "dateDebut": "Septembre 2019",
      "dateFin": "Juin 2020",
      "nom": "TroisPointZéro",
      "poste": "Développeur Web.",
      "description": "Réalisation d'un site web administrable pour un évènement universitaire national à travers une société universitaire."
    },
    "4": {
      "dateDebut": "Juin 2021",
      "dateFin": "Juillet 2021",
      "nom": "Mastercamp",
      "poste": "Chef de projet et développeur principal.",
      "description": "Projet react-native sur la numérisation d'ordonnances médicales."
    },
    "5": {
      "dateDebut": "Mars 2021",
      "dateFin": "Mars 2022",
      "nom": "Efrei FAP Horizon",
      "poste": "Développeur Web et logiciel.",
      "description": "Association universitaire développant des applications pour améliorer la vie étudiante."
    },
    "6": {
      "dateDebut": "Avril 2022",
      "dateFin": "Juillet 2022",
      "nom": "Master-project",
      "poste": "Chef de projet et développeur principal.",
      "description": "Projet web pour la prédiction des traitements médicaux grâce au machine leaning."
    },
    "7": {
      "dateDebut": "Septembre 2021",
      "dateFin": "Mars 2023",
      "nom": "SepEfrei",
      "poste": "Responsable des Systèmes d'Informations -> Directeur des Systèmes d'Informations.",
      "description": "Junior-Entreprise dans les domaines du multimédia."
    }
  },
  "mesCentresDinterets": {
    "photographie": "Photographie (Astro-photographie, depuis 2018)",
    "nouvelleTechnologie": {
      "principal": "Nouvelle technologie",
      "stream": "Technologie de diffusion de stream",
      "web": "Web technologie",
      "informatique": "Composant hardware",
      "connecter": "Objet connecté"
    },
    "jeuxVideo": "Jeux vidéo divers et variés",
    "tir": "Tir à l'arc (club et compétition, depuis 2013)",
    "piano": "Piano (cours et autodidacte, depuis 2009)"
  },
  "listeCertificts": {
    "titre": "Mes certifications",
    "afficher": "Afficher",
    "telecharger": "Télécharger",
    "certificats": {
      "js": "Apprenez à coder avec JavaScript",
      "htmlcss": "Apprenez à créer votre site web avec HTML5 et CSS3",
      "interactiveJS": "Créez des pages web interactives avec JavaScript",
      "illustrator": "Créez des visuels avec Illustrator",
      "vueJS": "Créez une application web avec Vue.js",
      "react": "Débuter avec React",
      "angular": "Développez des applications Web avec Angular",
      "reactNative": "Développez une application mobile React Native",
      "git": "Gérez votre code avec Git et GitHub",
      "machineLearning": "Le machine learning est il l'avenir de l'Homme ?",
      "apiRest": "Utilisez des API REST dans vos projets web"
    }
  },
  "avertissementEcran": {
    "avertissement": "Avertissement",
    "description": {
      "1": "Attention.",
      "2": "Ce site n'a pas été conçu pour les périphériques mobile ou de petite taille !",
      "3": "Il est donc possible que tout tout ne s'affiche pas corréctement, si vous continuer sur ce périphérique.",
      "4": "Êtes vous sur de vouloir continuer ?"
    },
    "continuer": "Continuer"
  },
  "miseAJour": {
    "content": "Dernière mise à jour",
    "date": "09 Juillet 2023"
  }
}