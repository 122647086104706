
  import {
    defineComponent, reactive, ref
  } from 'vue'
  import CertificatView from '@/components/CertificatView.vue'

  export default defineComponent({
    name: 'ListeCertificats',
    components: {
      CertificatView
    },
    setup() {

      const modalDisplay = ref(false)
      function switchModalDisplay() {
        modalDisplay.value = !modalDisplay.value
      }

      const files = reactive([
        {name: "Apprenez a coder avec JavaScript", i18n: "js", display: false},
        {name: "Apprenez a creer votre site web avec HTML5 et CSS3", i18n: "htmlcss", display: false},
        {name: "Creez des pages web interactives avec JavaScript", i18n: "interactiveJS", display: false},
        {name: "Creez des visuels avec Illustrator", i18n: "illustrator", display: false},
        {name: "Creez une application web avec VueJS", i18n: "vueJS", display: false},
        {name: "Debutez avec React", i18n: "react", display: false},
        {name: "Developpez des applications Web avec Angular", i18n: "angular", display: false},
        {name: "Developpez une application mobile React Native", i18n: "reactNative", display: false},
        {name: "Gerez votre code avec Git et GitHub", i18n: "git", display: false},
        {name: "Le machine learning est il l avenir de l Homme", i18n: "machineLearning", display: false},
        {name: "Utilisez des API REST dans vos projets web", i18n: "apiRest", display: false},
      ])

      function getUrlFile(file: string, type: string) {
        return window.location.origin + '/certificats/' + file + type
      }

      function switchFileModalDisplay(name: string) {
        const index = files.map(x => {return x.name}).indexOf(name)
        files[index].display = !files[index].display
      }

      return {
        modalDisplay,
        switchModalDisplay,
        files,
        getUrlFile,
        switchFileModalDisplay
      }
    }
  })
