
  import {
    defineComponent
  } from 'vue'

  export default defineComponent({
    name: 'CertificatView',
    components: {},
    props: {
      file: Object,
      switchFileModalDisplay: Function
    },
    setup() {

      function getUrlFile(file: string, type: string) {
        return window.location.origin + '/certificats/' + file + type
      }

      return {
        getUrlFile
      }
    }
  })
