import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3c4bbff7"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return _withDirectives((_openBlock(), _createElementBlock("select", {
    class: "h-12 px-4 bg-gray-900 hover:bg-gray-850 cursor-pointer border-2 border-gray-800 rounded transition duration-150 outline-none",
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.$i18n.locale) = $event))
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$i18n.availableLocales, (locale) => {
      return (_openBlock(), _createElementBlock("option", {
        class: "bg-gray-900 hover:bg-gray-850",
        key: `locale-${locale}`,
        value: locale
      }, _toDisplayString(_ctx.langageDisplay(locale)), 9, _hoisted_1))
    }), 128))
  ], 512)), [
    [_vModelSelect, _ctx.$i18n.locale]
  ])
}